import React from 'react'
import Gallery from '@components/Gallery';
import PortofolioLayout from '@components/PortofolioLayout';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import mockImg from "@images/projects/laminardata/frame-mock.png";
import architectureImg from "@images/projects/laminardata/architecture.png";
const screenshots = importAll(require.context("@images/projects/laminardata/ss", false, /\.(png|jpg|svg)$/));

function importAll(r) {
    return r.keys().map(r);
}



const laminarPage = ({ children, location }) => {
    return (
        <PortofolioLayout title="Demo App for Laminar Data">
            <div className="portofolio-project-wrapper ">
                <div className="portofolio-project laminar ">
{/*                     <div className="project-subtitle">
                        <div className="project-status"><span>Status: </span> Completed</div>
                        <div className="project-link"><a href="https://laminar-demo.herokuapp.com/" target="_blank">View Application</a></div>
                    </div> */}
                    <img src={mockImg} alt="laminar Screenshots" />
                    <div className="project-section project-intro">
                        <h2>Introduction</h2>
                        <p>
                            In this project, 3 other students and I developed an application to increase the business value of a software service owned by our industrial customer. The company, Snowﬂake Software, attempts to use our application project as a pitching demo application to potential clients. For this to occur, the application is supposed to provide innovative solutions and visualizations of the information offered through an API by the company, namely aviation data.
    We indulged into producing and displaying a number of representation ideas, some of which were very well received by our customer. We targeted a large number of stakeholders and attempted to cover all the types of data that the Snowﬂake’s API exposes. The ﬁnal application relies on a decoupled architecture design that was split into a back-end server and a front-end client, which is easily maintainable and thoroughly tested.
    The end product we delivered was very positively appreciated by the industrial customer who was very satisﬁed with the project’s outcome, as can be seen in the recommendation letter they offered. The application is soon to be used for its true production purpose, namely as a selling point for Snowﬂake’s services.<br />
                            I was responsible with the design and development of the web application, while the other 3 students worked on the backend application.
                            <br /> <br />
                            This project was part of the dissertation submitted for the award of Master's degree in Computer Science from University of Southampton.
                            <br />
                            The software was developed in 4 months, during my 3rd university year (October 2016 - January 2017).
                            <br />
                            A recommendation letter written by our customer is available <a href="https://drive.google.com/file/d/1QwUtDXL-IKGkxoQilDHmlQmRvmCVTYiY/view?usp=sharing" target="_blank"> here</a>.
                                <br />
                            The full report of the project is available <a href="https://drive.google.com/file/d/1sm0MawNCYSSZSlZ39psZMjY6EhCejEsv/view?usp=sharing" target="_blank"> here</a>.
                        </p>
                        <div className="project-tags">
                            <span>#AngularJS</span>
                            <span>#Flask</span>
                            <span>#Heroku</span>
                            <span>#AgileDevelopment</span>
                        </div>
                    </div>
                    <div className="project-section project-architecture">
                        <h2>Architecture </h2>
                        <img src={architectureImg} alt="laminar Architecture" />
                    </div>
                    <div className="project-section project-features">
                        <h2>Achievements</h2>
                        <ul>
                            <li><FontAwesomeIcon icon="check"></FontAwesomeIcon>Work closely with an industrial customer to satisfy their requirements</li>
                            <li><FontAwesomeIcon icon="check"></FontAwesomeIcon>Follow Agile Software Development principles</li>
                            <li><FontAwesomeIcon icon="check"></FontAwesomeIcon>Come up with innovative ways to display data</li>
                            <li><FontAwesomeIcon icon="check"></FontAwesomeIcon>Integrate the frontend application with a backend application developed by somebody else </li>
                            <li><FontAwesomeIcon icon="check"></FontAwesomeIcon>Write unit tests, integration tests</li>
                            <li><FontAwesomeIcon icon="check"></FontAwesomeIcon>Use Google Maps SDK</li>
                        </ul>
                    </div>
                    <div className="project-section project-screenshots">
                        <h2>Screenshots</h2>
                        <Gallery images={screenshots} />
                    </div>
                </div>
            </div>
        </PortofolioLayout>
    )
};

export default laminarPage;
